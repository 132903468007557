const sidebarStructure = [
  {
    id: "dashboard",
    title: "home",
    name: "Dashboard",
    parent: true,
    icon: "dashboard",
    link: "/home",
  },
  {
    id: "user_management",
    title: "user management",
    name: "User Management",
    parent: true,
    icon: "users",
    link: "/home/users",
  },
  // {
  //   id: "role_management",
  //   title: "role management",
  //   name: "Role Management",
  //   parent: true,
  //   icon: "roles",
  //   link: "/home/roles",
  // },
  {
    id: "building_management",
    title: "building management",
    name: "building_management",
    parent: true,
    icon: "building",
    child: [
      {
        id: "buildings",
        title: "building",
        name: "building_management.buildings",
        link: "/home/building",
        icon: "dot",
      },
      {
        id: "building_units",
        title: "buildingUnit",
        name: "building_management.building_units",
        link: "/home/buildingUnit",
        icon: "dot",
      },
    ],
  },
  {
    id: "tenant_management",
    title: "tenant",
    name: "Tenants",
    parent: true,
    icon: "tenants",
    link: "/home/tenant",
  },
  {
    id: "lease_management",
    title: "lease management",
    name: "lease_management",
    parent: true,
    icon: "leases",
    child: [
      {
        id: "leases",
        title: "leases",
        name: "lease_management.leases",
        link: "/home/leases",
        icon: "dot",
      },
      {
        id: "payment",
        title: "payment",
        name: "lease_management.payments",
        link: "/home/payment",
        icon: "dot",
      },
    ],
  },
  {
    id: "maintenance",
    title: "maintenance",
    name: "maintenance",
    parent: true,
    icon: "maintenance",
    child: [
      {
        id: "maintenance_request",
        title: "maintenanceRequest",
        name: "maintenance.maintenance_request",
        link: "/home/maintenance/request",
        icon: "dot",
      },
      {
        id: "maintenance_contractors",
        title: "maintenanceContractors",
        name: "maintenance.maintenance_contractors",
        link: "/home/maintenance/contractors",
        icon: "dot",
      },
      {
        id: "maintenance_workOrders",
        title: "maintenanceWorkOrders",
        name: "maintenance.maintenance_workOrders",
        link: "/home/maintenance/work-orders",
        icon: "dot",
      },
    ],
  },
  {
    id: "reports",
    title: "reports",
    name: "reports",
    parent: true,
    icon: "reports",
    child: [
      {
        id: "tenant_information_report",
        title: "tenantInformationReport",
        name: "reports.tenant_information_report",
        link: "/home/reports/tenant-information-report",
        icon: "dot",
      },
      {
        id: "maintenance_request_report",
        title: "maintenanceRequestReport",
        name: "report.maintenance_request_report",
        link: "/home/reports/maintenance-request-report",
        icon: "dot",
      },
      {
        id: "financial_report",
        title: "financialReport",
        name: "report.financial_report",
        link: "/home/reports/financial-report",
        icon: "dot",
      },
      {
        id: "lease_expiry_report",
        title: "leaseExpiryReport",
        name: "report.lease_expiry_report",
        link: "/home/reports/lease-expiry-report",
        icon: "dot",
      },
    ],
  },
];

export { sidebarStructure };
